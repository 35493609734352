import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import { push } from 'connected-react-router';
import { get } from 'lodash';

import { Location } from 'models/locations';
import Quote from 'models/quote';
import Search from 'models/search';
import Brand from 'models/brand';
import Event from 'models/event';
import Venue from 'models/venue';
import Hub from 'models/hub';

import BookableListing from 'components/search/list/bookable-listing';
import NonBookableListing from 'components/search/list/non-bookable-listing';
import CurrencySymbol from 'components/common/currency-symbol';

import highlightLocation from 'action-creators/search/highlight-location';
import setSelectedLocation from 'action-creators/search/set-selected-location';
import unsetScrolledLocationCreator from 'action-creators/search/unset-scrolled-location';
import trackEventCreator from 'action-creators/analytics/track-event';

import SoldoutCar from 'components/svgs/icons/soldout-car';
import { BOOK_NOW_CLICK, BOOK_NOW_IMPRESSION, MAP_LOCATION_LISTING_IMPRESSION } from 'lib/analytics/events';
import * as AppContext from 'lib/app-context';

import { ExperimentsConsumer } from 'providers/experiments-provider';

const propTypes = {
  location: PropTypes.instanceOf(Location).isRequired,
  quote: PropTypes.instanceOf(Quote).isRequired,
  currentSearch: PropTypes.instanceOf(Search).isRequired,
  list: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  position: PropTypes.string.isRequired,
  appContext: PropTypes.string.isRequired,
  displayMap: PropTypes.bool.isRequired,
  highlightedLocation: PropTypes.instanceOf(Location),
  scrolledLocation: PropTypes.instanceOf(Location),
  brand: PropTypes.instanceOf(Brand).isRequired,
  event: PropTypes.instanceOf(Event),
  venue: PropTypes.instanceOf(Venue),
  hub: PropTypes.instanceOf(Hub),
  clientSettings: PropTypes.instanceOf(Map),

  changeLocation: PropTypes.func.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  mouseenter: PropTypes.func.isRequired,
  mouseleave: PropTypes.func.isRequired,
  touchstart: PropTypes.func.isRequired,
  booktouchstart: PropTypes.func.isRequired,
  touchmove: PropTypes.func.isRequired,
  touchend: PropTypes.func.isRequired,
  touchcancel: PropTypes.func.isRequired,
  click: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  unsetScrolledLocation: PropTypes.func.isRequired,
  isCheckoutSurprise: PropTypes.bool,
};

const defaultProps = {
  highlightedLocation: null,
  scrolledLocation: null,
  list: null,
  event: null,
  venue: null,
  hub: null,
  isCheckoutSurprise: false,
  clientSettings: Map(),
};

class ParkingListing extends Component {
  constructor(props, context) {
    super(props);
    this.list = props.list;

    this.mouseenter = this.props.mouseenter.bind(this);
    this.mouseleave = this.props.mouseleave.bind(this);
    this.touchstart = this.props.touchstart.bind(this);
    this.booktouchstart = this.props.booktouchstart.bind(this);
    this.touchmove = this.props.touchmove.bind(this);
    this.touchend = this.props.touchend.bind(this);
    this.click = this.props.click.bind(this);
    this.touchcancel = this.props.touchcancel.bind(this);
    this.changeLocation = this.changeLocation.bind(this);
    this.scrollToTop = this.props.scrollToTop;
    this.onBookNow = this.onBookNow.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);

    this.renderGuarantee = this.renderGuarantee.bind(this);
    this.renderMonthlyName = this.renderMonthlyName.bind(this);
    this.renderPackageName = this.renderPackageName.bind(this);
    this.renderAddress = this.renderAddress.bind(this);
    this.renderName = this.renderName.bind(this);
    this.renderPrice = this.renderPrice.bind(this);
    this.renderPhone = this.renderPhone.bind(this);
    this.renderBookNowButton = this.renderBookNowButton.bind(this);
    this.renderDetailLink = this.renderDetailLink.bind(this);

    this.context = context;
  }

  componentWillMount() {
    this.setState({ hover: false });
  }

  shouldComponentUpdate(nextProps) {
    const { location } = this.props;
    if (
      (nextProps.highlightedLocation && !nextProps.highlightedLocation.equals(location)) ||
      (nextProps.scrolledLocation && !nextProps.scrolledLocation.equals(location))
    ) {
      return false;
    }
    return true;
  }

  componentDidUpdate() {
    const { scrolledLocation, location, list, unsetScrolledLocation, appContext } = this.props;
    const { listing } = this;
    if (location.equals(scrolledLocation)) {
      if (appContext === AppContext.DESKTOP) {
        list.scrollTo({ top: listing.offsetTop, behavior: 'smooth' });
      } else {
        window.scrollTo({ top: listing.offsetTop });
      }
      unsetScrolledLocation();
    }
  }

  changeLocation(e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    const { location, quote } = this.props;

    this.props.changeLocation({
      locationId: location.id,
      quoteId: (quote && quote.id),
      eventPackageId: (quote && quote.eventPackageId),
    });
  }

  onBookNow(e) {
    e.stopPropagation();
    // e.preventDefault();

    // if the parking type is monthly we want them to always
    // go to the details page
    const { currentSearch } = this.props;
    if (currentSearch.isMonthlySearch) {
      this.changeLocation();
      return;
    }

    const { location, trackEvent, position } = this.props;

    trackEvent({
      ...BOOK_NOW_CLICK,
      position,
      properties: {
        'Location ID': location.id,
      },
    });
  }

  onVisibilityChange(isVisible) {
    const { location, trackEvent, position, appContext, displayMap } = this.props;

    if (isVisible && (appContext === AppContext.DESKTOP || !displayMap)) {
      trackEvent({
        ...MAP_LOCATION_LISTING_IMPRESSION,
        position,
        properties: {
          'Location ID': location.id,
        },
      });
      trackEvent({
        ...BOOK_NOW_IMPRESSION,
        position,
        properties: {
          'Location ID': location.id,
        },
      });
    }
  }

  renderGuarantee(quote) {
    if (quote.price_guarantee === 'discount') {
      return (
        <div>Special online price!</div>
      );
    }
    return null;
  }

  renderPhone() {
    if (!this.props.currentSearch.isMonthlySearch) {
      return null;
    }

    const phone = this.props.currentSearch.brandedMonthlyPhoneNumber(this.props.brand);

    if (phone) {
      return (
        <div className="monthly-phone">Questions? call <a className="phone-icon" href={`tel:${phone}`}>{phone}</a></div>
      );
    }

    return null;
  }

  renderDetailLink(isSoldOut = false) {
    if (isSoldOut) {
      return null;
    }

    const { currentSearch, location, quote, displayMap, appContext, brand } = this.props;
    const { routingStyle } = brand;
    const { eventPackageId } = quote;

    return (
      <div className="details-link col-sm-4 col-xs-4 text-align-right">
        <Link
          to={{
            pathname: currentSearch.route(location, displayMap, appContext, { eventPackageId, routingStyle }),
            state: {
              app: { name: 'Search' },
              search: {
                currentSearch: currentSearch.toJSON(),
                locationId: location.id,
                displayMap,
                selectedLocation: location,
                selectedQuote: quote,
              },
            },
          }}
        >
          DETAILS
        </Link>
      </div>
    );
  }

  renderBookNowButton(showUrl = true, soldOut = false) {
    const { location, quote, currentSearch, displayMap, event, venue, hub, brand } = this.props;
    const quoteId = quote ? quote.id : null;
    const shouldRedirectToNewCheckout = currentSearch.isDailySearch || currentSearch.isEventSearch;

    if (showUrl && !soldOut) {
      return (
        <div className="booking-button-wrapper" >
          {shouldRedirectToNewCheckout ? (
            <a
              href={`/checkout?quote_id=${quoteId}`}
              onClick={this.onBookNow}
              onTouchEnd={this.onBookNow}
              className={`btn btn-primary ${brand.isBestParking ? 'btn-bp' : null}`}
            >
              Book Now
            </a>
            ):(
            <Link
              to={{
                pathname: '/reserve',
                search: `?quote_id=${quoteId}`,
                state: {
                  app: { name: 'Checkout' },
                  search: {
                    currentSearch: currentSearch.toJSON(),
                    event: event && event.toJSON(),
                    venue: venue && venue.toJSON(),
                    hub: hub && hub.toJSON(),
                    locationId: location.id,
                    quoteId: quote.id,
                    selectedLocation: location.toJSON(),
                    selectedQuote: quote.toJSON(),
                    displayMap,
                  },
                },
              }}
              onClick={this.onBookNow}
              onTouchEnd={this.onBookNow}
              className={`btn btn-primary ${brand.isBestParking ? 'btn-bp' : null}`}
            >
              Book Now
            </Link>
            )
          }
        </div>
      );
    } else if (soldOut) {
      return (
        <div className="booking-button-wrapper">
          <span className="btn btn-primary btn-disabled sold-out">
            <span className="isvg loaded">
              <SoldoutCar />
            </span>
            <span className="btn-text">Sorry Sold Out</span>
          </span>
        </div>
      );
    }
    return null;
  }

  renderMonthlyName() {
    if (this.props.currentSearch && this.props.currentSearch.parkingType !== 'Monthly') {
      return null;
    }

    // Only render the monthly name if there is more than one location quote
    const locationQuotes = get(this.props, 'location.quotes', null);
    if (locationQuotes && locationQuotes.size < 2) {
      return null;
    }

    const { quote } = this.props;
    const monthlyName = quote ? quote.name : null;
    if (!monthlyName) {
      return null;
    }

    return (
      <div className="monthly-name">{monthlyName}</div>
    );
  }

  renderName() {
    const { currentSearch, location } = this.props;

    if (currentSearch.isPackageSearch) {
      return null;
    }

    return (
      <div className="location-name">
        { location.name }
      </div>
    );
  }

  renderAddress() {
    // Return a subdued address for packages
    if (this.props.currentSearch.isPackageSearch) {
      return (<div className="line-height-full text-weight-book text-color-grey text-size-12 margin-top-5 margin-bottom-10 wrap-ellipses">{ this.props.location.address }</div>);
    }
    return (<div className="address">{ this.props.location.address }</div>);
  }

  renderPackageName() {
    if (!this.props.currentSearch || !this.props.currentSearch.isPackageSearch) {
      return null;
    }

    const packageName = this.props.quote ? this.props.quote.name : '';
    return (<div className="font-weight-book text-color-slate text-size-16 margin-bottom-15">{packageName}</div>);
  }

  renderPrice(price) {
    if (price) {
      const { location } = this.props;

      return (
        <div className="listing-price text-align-right">
          <sup>
            <CurrencySymbol location={location} />
          </sup>
          { price[0] }
          { price.length === 2 ? <sup>{ price[1] }</sup> : null }
        </div>
      );
    }

    return null;
  }

  render() {
    const { clientSettings, isCheckoutSurprise, location, quote } = this.props;
    const isSoldOut = !quote || quote.isSoldOut;
    if (isSoldOut) return null;
    const showUrl = !!(quote && quote.price >= 0);
    const isHighlighted = (location === this.props.highlightedLocation);
    const price = quote ? quote.getListingPrice(isCheckoutSurprise, location, clientSettings) : null;
    const formattedPrice = price ? price.toString().split('.') : null;

    const thumb = location.getSearchThumbnail();
    const hover = (this.state.hover || isHighlighted) ? ' hover' : '';
    const soldOut = isSoldOut ? ' sold-out' : '';
    const cls = (this.props.currentSearch.isEventSearch ? 'listing event-listing' : 'listing') + hover + soldOut;

    if ((quote && quote.bookable) || isSoldOut) {
      return (<div ref={(l) => { this.listing = l; }}>
        <BookableListing
          location={location}
          quote={quote}
          thumb={thumb}
          formattedPrice={formattedPrice}
          showUrl={showUrl}
          isSoldOut={isSoldOut}
          cls={cls}
          click={this.click}
          mouseenter={this.mouseenter}
          mouseleave={this.mouseleave}
          touchstart={this.touchstart}
          touchmove={this.touchmove}
          touchend={this.touchend}
          touchcancel={this.touchcancel}
          onVisibilityChange={this.onVisibilityChange}
          renderGuarantee={this.renderGuarantee}
          renderMonthlyName={this.renderMonthlyName}
          renderPackageName={this.renderPackageName}
          renderAddress={this.renderAddress}
          renderName={this.renderName}
          renderPrice={this.renderPrice}
          renderPhone={this.renderPhone}
          renderBookNowButton={this.renderBookNowButton}
          renderDetailLink={this.renderDetailLink}
        />
      </div>);
    }

    return (<div ref={(l) => { this.listing = l; }}>
      <NonBookableListing
        location={location}
        quote={quote}
        thumb={thumb}
        formattedPrice={formattedPrice}
        showUrl={showUrl}
        cls={cls}
        mouseenter={this.mouseenter}
        mouseleave={this.mouseleave}
        onVisibilityChange={this.onVisibilityChange}
        renderGuarantee={this.renderGuarantee}
        renderMonthlyName={this.renderMonthlyName}
        renderAddress={this.renderAddress}
        renderName={this.renderName}
        renderPrice={this.renderPrice}
        renderPhone={this.renderPhone}
      />
    </div>);
  }
}

ParkingListing.propTypes = propTypes;
ParkingListing.defaultProps = defaultProps;
ParkingListing.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    currentSearch,
    highlightedLocation,
    displayMap,
    hub,
    scrolledLocation,
  } = state.search;

  const {
    appContext,
    clientSettings,
  } = state.app;

  const {
    brand,
  } = state.brand;

  return {
    ...ownProps,
    currentSearch,
    highlightedLocation,
    displayMap,
    hub,
    appContext,
    clientSettings,
    brand,
    scrolledLocation,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setSelectedLocation,
    highlightLocation,
    unsetScrolledLocation: unsetScrolledLocationCreator,
    push,
    trackEvent: trackEventCreator,
  }, dispatch)
);

const ParkingListingWrapper = props => (
  <ExperimentsConsumer>
    <ParkingListing {...props} />
  </ExperimentsConsumer>
);

export default connect(mapStateToProps, mapDispatchToProps)(ParkingListingWrapper);
