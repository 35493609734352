import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { canUseDOM } from 'exenv';
import { Map, OrderedMap } from 'immutable';
import cx from 'classnames';

let mapboxgl;
let LngLatBounds;
let LngLat;
let NavigationControl;
let MapboxMap;
let mapboxglSupported;
if (canUseDOM) {
  // eslint-disable-next-line global-require
  mapboxgl = require('mapbox-gl');
  // eslint-disable-next-line global-require
  mapboxglSupported = require('@mapbox/mapbox-gl-supported');
  ({
    LngLatBounds,
    LngLat,
    NavigationControl,
    Map: MapboxMap,
  } = mapboxgl);
}

import { ExperimentsConsumer } from 'providers/experiments-provider';

import StreetParkingControls from 'containers/search/street-parking-controls';

import LoadingPulse from 'components/common/loading-pulse';
import ToggleBar from 'components/map/toggle-bar';
import LocationMarker from 'components/search/map/markers/location';
import DestinationMarker from 'components/search/map/markers/destination';
import MarkerWrapper from 'components/search/map/markers/wrapper';
import StreetParkingSegmentLayer from 'components/search/map/street-parking/segment-layer';

import Search from 'models/search';
import { Location } from 'models/locations';
import Quote from 'models/quote';

import env from 'lib/env';
import { unsupportedBrowserMessage } from 'lib/common/messages';

import highlightLocation from 'action-creators/search/highlight-location';
import changeSelectedLocation from 'action-creators/search/change-selected-location';
import boundsChange from 'action-creators/search/bounds-change';
import updateCurrentSearch from 'action-creators/search/update-current-search';
import toggleMap from 'action-creators/search/toggle-map';
import parkingNearMeSearch from 'action-creators/search/parking-near-me-search';
import setCurrentSearchAndChangeBounds from 'action-creators/search/set-current-search-and-change-bounds';
import trackEventCreator from 'action-creators/analytics/track-event';
import scrollToLocation from 'action-creators/search/scroll-to-location';
import addMessage from 'action-creators/messaging/add-message';

import { usesLargeFormatLocationDetails } from 'lib/common/search-helpers';

const { MAPBOX_TOKEN, MAPBOX_STYLE } = env();
const MAPBOX_STYLE_URL = `mapbox://styles/${MAPBOX_STYLE}`;
const IDEAL_LOCATION_DISTANCE = 2640; // Half a mile in feet
const IDEAL_NUM_LOCATIONS = 5;

const PENDING_GEOLOCATION_ZOOM = 12;
const DEFAULT_INITIAL_ZOOM = 15;

const LOCATION_BOUNDS_PADDING = { padding: { top: 100, bottom: 25, left: 50, right: 100 }, maxZoom: 18 };
const MAP_BOUNDS_PADDING = { padding: { top: 75, bottom: 25, left: 25, right: 100 }, maxZoom: 16 };

const propTypes = {
  currentSearch: PropTypes.instanceOf(Search).isRequired,
  previousSearch: PropTypes.instanceOf(Search).isRequired,
  locations: PropTypes.instanceOf(OrderedMap).isRequired,
  events: PropTypes.instanceOf(OrderedMap).isRequired,
  streetParking: PropTypes.instanceOf(Map).isRequired,
  showStreetParking: PropTypes.bool.isRequired,
  limitEventZoomToFirstTen: PropTypes.bool,
  isCheckoutSurprise: PropTypes.bool,
  clientSettings: PropTypes.instanceOf(Map),

  highlightedLocation: PropTypes.instanceOf(Location),
  selectedLocation: PropTypes.instanceOf(Location),
  selectedQuote: PropTypes.instanceOf(Quote),
  airport: PropTypes.bool,
  // forceRefresh: PropTypes.bool,

  // parkingNearMeSearch: PropTypes.func.isRequired,
  updateCurrentSearch: PropTypes.func.isRequired,
  changeSelectedLocation: PropTypes.func.isRequired,
  boundsChange: PropTypes.func.isRequired,
  highlightLocation: PropTypes.func.isRequired,
  toggleMap: PropTypes.func.isRequired,
  scrollToLocation: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
};

const defaultProps = {
  highlightedLocation: null,
  selectedLocation: null,
  selectedQuote: null,
  airport: false,
  forceRefresh: false,
  limitEventZoomToFirstTen: false,
  isCheckoutSurprise: false,
  clientSettings: Map(),
};

export class SearchMap extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { currentSearch, selectedLocation } = nextProps;
    const { destination, zoomLevel: zoom } = currentSearch;
    let { lat, lng } = currentSearch;

    // Re-center map if destination changes, or if location selected
    if (destination !== prevState.destination || selectedLocation) {
      ({ anchorLat: lat, anchorLng: lng } = currentSearch);
    }

    const recenter = destination !== prevState.destination &&
                    !(prevState.selectedLocation && !nextProps.selectedLocation);

    let { prevBounds } = prevState;
    if (!prevState.selectedLocation && nextProps.selectedLocation) {
      prevBounds = new LngLatBounds();
      if (currentSearch.bounds.size === 4) {
        const ne = currentSearch.bounds.get(1).split(',');
        const sw = currentSearch.bounds.get(3).split(',');
        prevBounds.extend(new LngLat(ne[1], ne[0]));
        prevBounds.extend(new LngLat(sw[1], sw[0]));
      } else {
        const ne = currentSearch.bounds.get(0).split(',');
        const sw = currentSearch.bounds.get(1).split(',');
        prevBounds.extend(new LngLat(ne[1], ne[0]));
        prevBounds.extend(new LngLat(sw[1], sw[0]));
      }
    }


    return {
      ...prevState,
      destination,
      selectedLocation,
      lat,
      lng,
      zoom,
      prevBounds,
      recenter,
      currentSearch,
    };
  }

  constructor(props) {
    super(props);

    this.idealLocationDistance = IDEAL_LOCATION_DISTANCE;
    this.idealNumLocations = IDEAL_NUM_LOCATIONS;

    const { currentSearch, selectedLocation } = props;
    const { lat, lng } = currentSearch;
    const { destination } = currentSearch;
    const zoom = currentSearch.pendingGeolocationPermission ? PENDING_GEOLOCATION_ZOOM : DEFAULT_INITIAL_ZOOM;

    this.state = {
      lng,
      lat,
      destination,
      selectedLocation,
      zoom,
      refitBounds: false,
      recenter: false,
      bounds: null,
      prevBounds: null,
    };

    this.grantedGeolocationPermission = this.grantedGeolocationPermission.bind(this);
    this.deniedGeolocationPermission = this.deniedGeolocationPermission.bind(this);
    this.resizeMap = this.resizeMap.bind(this);
    this.configureMap = this.configureMap.bind(this);
    if (mapboxgl) { mapboxgl.accessToken = MAPBOX_TOKEN; }
  }

  componentDidMount() {
    const { currentSearch } = this.props;
    const { lat, lng } = currentSearch;
    if (mapboxglSupported && mapboxglSupported()) {
      const map = new MapboxMap({
        container: this.mapContainer,
        style: MAPBOX_STYLE_URL,
        center: [lng, lat],
        zoom: 16,
        pitchWithRotate: false,
        dragRotate: false,
      });

      this.map = map;
      this.map.on('load', this.configureMap);
    } else {
      this.props.addMessage(unsupportedBrowserMessage);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.map) return;
    const { currentSearch, previousSearch, selectedLocation, locations } = this.props;
    const { recenter, lat, lng } = this.state;

    if (recenter) {
      this.map.setCenter([this.state.lng, this.state.lat]);
    }

    if (!selectedLocation) {
      if (prevProps.selectedLocation && currentSearch.destination === prevProps.currentSearch.destination) {
        const { prevBounds } = this.state;
        if (prevBounds) {
          this.boundsChange(true, { prevBounds });
          this.map.fitBounds(prevBounds, MAP_BOUNDS_PADDING);
        } else {
          const { bounds } = this.calculateBounds();
          this.map.fitBounds(bounds, MAP_BOUNDS_PADDING);
        }
      } else if (
        (
          currentSearch.isDirty(prevProps.previousSearch) &&
          !currentSearch.isDirty(previousSearch) &&
          (
            currentSearch.destination !== prevProps.previousSearch.destination ||
            currentSearch.parkingType !== prevProps.previousSearch.parkingType
          )
        ) ||
        (locations.size !== 0 && prevProps.locations.size === 0)
      ) {
        // Search just resolved, refit
        const { bounds } = this.calculateBounds();
        this.map.fitBounds(bounds, MAP_BOUNDS_PADDING);
      }
    } else if (selectedLocation !== prevProps.selectedLocation) {
      const bounds = new LngLatBounds();
      bounds.extend(new LngLat(lng, lat));
      bounds.extend(new LngLat(
        selectedLocation.entrances.first().lng,
        selectedLocation.entrances.first().lat,
      ));
      this.map.fitBounds(bounds, LOCATION_BOUNDS_PADDING);
    }

    if (
      (usesLargeFormatLocationDetails(this.props) !== usesLargeFormatLocationDetails(prevProps)) ||
      (this.props.selectedLocation && !prevProps.selectedLocation) ||
      (!this.props.selectedLocation && prevProps.selectedLocation)
    ) {
      // Resize the map on a delay, otherwise the transition
      // may finish after his call takes place
      this.resizeMap();
    }
  }

  configureMap() {
    const { currentSearch, selectedLocation } = this.props;
    const enableBounds = !currentSearch.isPackageSearch;

    this.attachZoomControllers();

    if (!currentSearch.pendingGeolocationPermission) {
      if (!selectedLocation) {
        const { bounds } = this.calculateBounds();
        this.map.fitBounds(bounds, MAP_BOUNDS_PADDING);
      } else {
        const bounds = new LngLatBounds();
        const destination = new LngLat(this.props.currentSearch.anchorLng, this.props.currentSearch.anchorLat);
        bounds.extend(destination);
        bounds.extend(new LngLat(
          selectedLocation.entrances.first().lng,
          selectedLocation.entrances.first().lat,
        ));
        this.map.fitBounds(bounds, LOCATION_BOUNDS_PADDING);
      }
    }
    this.attachMapEvents(this.map);

    if (enableBounds) {
      this.attachMapSearchEvents(this.map);
    }

    if (currentSearch.isParkingNearMeSearch && currentSearch.pendingGeolocationPermission) {
      this.getCurrentLocation();
    }
  }

  resizeMap() {
    if (this.resizeTimeout) {
      window.clearTimeout(this.resizeTimeout);
    }

    this.resizeTimeout = window.setTimeout(() => { this.map.resize(); }, 250);
  }

  attachMapEvents(map) {
    map.on('click', () => {
      this.props.changeSelectedLocation({ locationId: null });
    });

    window.addEventListener('resize', this.resizeMap);
    window.onresize = this.resizeMap;
  }

  attachMapSearchEvents(map) {
    map.on('dragend', () => {
      this.boundsChange();
    });

    map.on('zoomend', () => {
      if (!this.props.selectedLocation && !this.props.currentSearch.pendingGeolocationPermission) {
        this.boundsChange();
      }
    });
  }

  attachZoomControllers() {
    const zoomControl = new NavigationControl({ showCompass: false });
    this.map.addControl(zoomControl, 'top-right');
  }

  shouldAddQuoteToBounds(quote, { numLocations, numEventPricings }) {
    if (!quote) { return false; }
    const { limitEventZoomToFirstTen, currentSearch } = this.props;

    // We only want to show locations that fall into the ideal zoom
    const idealZoomReached = numLocations >= this.idealNumLocations || location.distance >= this.idealLocationDistance;
    // Exception is 1) Locations with shuttles
    // 2) Event Pricings
    // 3) Event Packages
    const { isEventRelated } = quote;

    if (limitEventZoomToFirstTen && currentSearch.isEventSearch) {
      return !idealZoomReached || (numEventPricings < 10 && isEventRelated);
    }

    return !idealZoomReached || isEventRelated;
  }

  calculateBounds() {
    const bounds = new LngLatBounds();

    const destination = new LngLat(this.props.currentSearch.anchorLng, this.props.currentSearch.anchorLat);
    let numLocations = 0;
    let numEventPricings = 0;
    const { locations } = this.props;

    // Have the bounds always include the destination
    bounds.extend(destination);

    locations.sortBy(l => l.distance).forEach((location) => {
      const quote = location.getQuote();
      if (this.shouldAddQuoteToBounds(quote, { numLocations, numEventPricings })) {
        const point = new LngLat(location.entrances.get(0).lng, location.entrances.get(0).lat);
        bounds.extend(point);
        numLocations += 1;
        if (quote.isEventRelated) {
          numEventPricings += 1;
        }
      }
    });

    return {
      bounds,
      numLocations,
    };
  }

  boundsChange(router = false, { prevBounds = null } = {}) {
    const { selectedLocation } = this.props;
    const { map } = this;
    if (!selectedLocation && !map.isMoving()) {
      const bounds = prevBounds || map.getBounds();
      const center = map.getCenter();
      const zoomLevel = map.getZoom();
      this.props.boundsChange({
        bounds,
        lat: center.lat,
        lng: center.lng,
        zoomLevel,
        router,
      });
    }
  }

  getCurrentLocation() {
    window.navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        this.grantedGeolocationPermission(coords);
      },
      (error) => {
        const { lat, lng } = this.props.currentSearch;
        // error code 1 is user denying geolocation, others are browser failure
        if (error.code !== 1) {
          this.grantedGeolocationPermission({ latitude: lat, longitude: lng });
        } else {
          this.deniedGeolocationPermission();
        }
      },
      {
        enableHighAccuracy: true,
      },
    );
  }

  grantedGeolocationPermission(coords) {
    const { latitude, longitude } = coords;
    this.props.updateCurrentSearch({
      newSearch: this.props.currentSearch.merge({
        lat: latitude,
        lng: longitude,
        anchorLat: latitude,
        anchorLng: longitude,
        pendingGeolocationPermission: false,
        zoomLevel: null,
      }),
    });
  }

  deniedGeolocationPermission() {
    this.props.updateCurrentSearch({
      newSearch: this.props.currentSearch.set('pendingGeolocationPermission', false),
    });
  }

  markerPriceForLocation(location) {
    if (!location) { return 0; }
    const { airport, clientSettings, currentSearch, isCheckoutSurprise, selectedQuote } = this.props;

    let price = 0;

    if (location.quotes) {
      const quote = location.getQuote();
      if (airport) {
        price = quote.price;
      } else {
        price = quote.getListingPrice(isCheckoutSurprise, location, clientSettings);
      }
    }

    if (currentSearch.parkingType === Search.MONTHLY_PARKING_TYPE) {
      const quote = selectedQuote ? location.getQuoteById(selectedQuote.id) : location.getDefaultQuote();
      if (quote) {
        price = quote.price;
      }
    }

    return price;
  }


  get locationMarkers() {
    const { highlightedLocation, selectedLocation } = this.props;
    let { locations } = this.props;
    locations = locations.sortBy(l => l.entrances.first().lat).toArray();

    return locations.map((location, i) => (
      <MarkerWrapper
        key={`lm-${location.id}`}
        position={location.entrances.first()}
        map={this.map}
        zIndex={LocationMarker.zIndex({
          location,
          selectedLocation,
          highlightedLocation,
          latRank: i,
          locationCount: locations.length,
        })}
      >
        <LocationMarker
          location={location}
          price={this.markerPriceForLocation(location)}
          selectedLocation={selectedLocation}
          highlightedLocation={highlightedLocation}
          changeSelectedLocation={this.props.changeSelectedLocation}
          scrollToLocation={this.props.scrollToLocation}
          highlightLocation={this.props.highlightLocation}
          toggleMap={this.props.toggleMap}
          latRank={i}
          locationCount={locations.length}
        />
      </MarkerWrapper>
    ));
  }

  get streetParking() {
    const { currentSearch, showStreetParking, streetParking, selectedLocation } = this.props;
    if (!currentSearch.isMonthlySearch && showStreetParking && !selectedLocation) {
      return (streetParking.groupBy(s => s.availability).map(s => (<StreetParkingSegmentLayer
        key={`street-${s.first().availability}`}
        segments={s}
        map={this.map}
        zoomLevel={this.state.zoom}
      />)).toArray());
    }
    return null;
  }

  get destinationCoordinates() {
    const { currentSearch } = this.props;
    return { lat: currentSearch.anchorLat, lng: currentSearch.anchorLng };
  }

  render() {
    const { currentSearch, selectedLocation } = this.props;
    const mapClasses = cx({
      'airport-map': usesLargeFormatLocationDetails(this.props),
    });

    const wrapperClasses = cx({
      'map-wrapper': true,
      'airport-map-wrapper': usesLargeFormatLocationDetails(this.props),
      'visible-xs': usesLargeFormatLocationDetails(this.props) && selectedLocation,
      'visible-sm': usesLargeFormatLocationDetails(this.props) && selectedLocation,
    });

    return (
      <div className={wrapperClasses}>
        <ToggleBar
          selectedLocation={this.props.selectedLocation}
          events={this.props.events}
          currentSearch={currentSearch}
          changeSelectedLocation={this.props.changeSelectedLocation}
          toggleMap={this.props.toggleMap}
        />
        <LoadingPulse visible={this.state.loading} />
        <StreetParkingControls />
        <div ref={(m) => { this.mapContainer = m; }} className={mapClasses} id="map">
          <MarkerWrapper
            position={this.destinationCoordinates}
            map={this.map}
            zIndex={0}
          >
            <DestinationMarker />
          </MarkerWrapper>
          {this.locationMarkers}
          {this.streetParking}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeSelectedLocation,
    highlightLocation,
    boundsChange,
    updateCurrentSearch,
    parkingNearMeSearch,
    toggleMap,
    setCurrentSearchAndChangeBounds,
    trackEvent: trackEventCreator,
    scrollToLocation,
    addMessage,
  }, dispatch)
);

const mapStateToProps = (state, ownProps) => ({
  locations: state.search.locations,
  clientSettings: state.app.clientSettings,
  currentSearch: state.search.currentSearch,
  previousSearch: state.search.previousSearch,
  highlightedLocation: state.search.highlightedLocation,
  forceMapRefresh: state.search.forceMapRefresh,
  insights: state.analytics.insights,
  event: state.search.event,
  events: state.search.events,
  selectedLocation: state.search.selectedLocation,
  selectedQuote: state.search.selectedQuote,
  streetParking: state.search.streetParking,
  showStreetParking: state.search.showStreetParking,
  airport: ownProps.airport,
});

const SearchMapWrapper = props => (
  <ExperimentsConsumer>
    <SearchMap {...props} />
  </ExperimentsConsumer>
);

SearchMap.propTypes = propTypes;
SearchMap.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(SearchMapWrapper);
